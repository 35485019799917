/**
 * Customer Project Manager
 * EMSFACTORY Customer Project Manager REST API
 *
 * OpenAPI spec version: 1.0.0
 * Contact: texier@emsfactory.com
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *
 */
import ApiClient from '../ApiClient';

export default class Statistics {
  constructor() {


  }

  static typeDescription() {
    return "Statistics";
  }



  static constructFromObject(data, obj) {
    if (data) {
      obj = obj || new Statistics();
      if (data.hasOwnProperty('quotations_created')) {
        obj['quotations_created'] = ApiClient.convertToType(data['quotations_created'], { 'String': 'Number' });
      }
      if (data.hasOwnProperty('quotations_priced')) {
        obj['quotations_priced'] = ApiClient.convertToType(data['quotations_priced'], { 'String': 'Number' });
      }
      if (data.hasOwnProperty('quotations_manually_priced')) {
        obj['quotations_manually_priced'] = ApiClient.convertToType(data['quotations_manually_priced'], { 'String': 'Number' });
      }
      if (data.hasOwnProperty('quotations_ordered')) {
        obj['quotations_ordered'] = ApiClient.convertToType(data['quotations_ordered'], { 'String': 'Number' });
      }
      if (data.hasOwnProperty('quotes_created')) {
        obj['quotes_created'] = ApiClient.convertToType(data['quotes_created'], 'Number');
      }
      if (data.hasOwnProperty('quotes_expired')) {
        obj['quotes_expired'] = ApiClient.convertToType(data['quotes_expired'], 'Number');
      }
      if (data.hasOwnProperty('orders_created')) {
        obj['orders_created'] = ApiClient.convertToType(data['orders_created'], 'Number');
      }
      if (data.hasOwnProperty('orders_expired')) {
        obj['orders_expired'] = ApiClient.convertToType(data['orders_expired'], 'Number');
      }
      if (data.hasOwnProperty('orders_validated')) {
        obj['orders_validated'] = ApiClient.convertToType(data['orders_validated'], 'Number');
      }
      if (data.hasOwnProperty('orders_manually_priced_validated')) {
        obj['orders_manually_priced_validated'] = ApiClient.convertToType(data['orders_manually_priced_validated'], 'Number');
      }
      if (data.hasOwnProperty('accounts_created')) {
        obj['accounts_created'] = ApiClient.convertToType(data['accounts_created'], 'Number');
      }
      if (data.hasOwnProperty('pcb_total')) {
        obj['pcb_total'] = ApiClient.convertToType(data['pcb_total'], 'Number');
      }
      if (data.hasOwnProperty('pcb_customer_supplied')) {
        obj['pcb_customer_supplied'] = ApiClient.convertToType(data['pcb_customer_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_auto_priced')) {
        obj['pcb_auto_priced'] = ApiClient.convertToType(data['pcb_auto_priced'], 'Number');
      }
      if (data.hasOwnProperty('pcb_manual_priced')) {
        obj['pcb_manual_priced'] = ApiClient.convertToType(data['pcb_manual_priced'], 'Number');
      }
      if (data.hasOwnProperty('pcb_rfq_priced')) {
        obj['pcb_rfq_priced'] = ApiClient.convertToType(data['pcb_rfq_priced'], 'Number');
      }
      if (data.hasOwnProperty('pcb_out_of_standard')) {
        obj['pcb_out_of_standard'] = ApiClient.convertToType(data['pcb_out_of_standard'], 'Number');
      }
      if (data.hasOwnProperty('pcb_eurocircuits_supplied')) {
        obj['pcb_eurocircuits_supplied'] = ApiClient.convertToType(data['pcb_eurocircuits_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_gatemapcb_supplied')) {
        obj['pcb_gatemapcb_supplied'] = ApiClient.convertToType(data['pcb_gatemapcb_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_mcbpcb_supplied')) {
        obj['pcb_mcbpcb_supplied'] = ApiClient.convertToType(data['pcb_mcbpcb_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_safepcb_supplied')) {
        obj['pcb_safepcb_supplied'] = ApiClient.convertToType(data['pcb_safepcb_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_wurthpcb_supplied')) {
        obj['pcb_wurthpcb_supplied'] = ApiClient.convertToType(data['pcb_wurthpcb_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_rfq_supplied')) {
        obj['pcb_rfq_supplied'] = ApiClient.convertToType(data['pcb_rfq_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcb_manual_supplied')) {
        obj['pcb_manual_supplied'] = ApiClient.convertToType(data['pcb_manual_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_total')) {
        obj['bom_lines_total'] = ApiClient.convertToType(data['bom_lines_total'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_erased')) {
        obj['bom_lines_erased'] = ApiClient.convertToType(data['bom_lines_erased'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_supplied')) {
        obj['bom_lines_supplied'] = ApiClient.convertToType(data['bom_lines_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_mpn')) {
        obj['bom_lines_with_mpn'] = ApiClient.convertToType(data['bom_lines_with_mpn'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_without_mpn')) {
        obj['bom_lines_without_mpn'] = ApiClient.convertToType(data['bom_lines_without_mpn'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_sku')) {
        obj['bom_lines_with_sku'] = ApiClient.convertToType(data['bom_lines_with_sku'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_without_sku')) {
        obj['bom_lines_without_sku'] = ApiClient.convertToType(data['bom_lines_without_sku'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_mpn_and_sku')) {
        obj['bom_lines_with_mpn_and_sku'] = ApiClient.convertToType(data['bom_lines_with_mpn_and_sku'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_mpn_without_sku')) {
        obj['bom_lines_with_mpn_without_sku'] = ApiClient.convertToType(data['bom_lines_with_mpn_without_sku'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_sku_without_mpn')) {
        obj['bom_lines_with_sku_without_mpn'] = ApiClient.convertToType(data['bom_lines_with_sku_without_mpn'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_description')) {
        obj['bom_lines_with_description'] = ApiClient.convertToType(data['bom_lines_with_description'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_description_only')) {
        obj['bom_lines_with_description_only'] = ApiClient.convertToType(data['bom_lines_with_description_only'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_without_description')) {
        obj['bom_lines_without_description'] = ApiClient.convertToType(data['bom_lines_without_description'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_with_xref_allowed')) {
        obj['bom_lines_with_xref_allowed'] = ApiClient.convertToType(data['bom_lines_with_xref_allowed'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_total')) {
        obj['bom_lines_group_total'] = ApiClient.convertToType(data['bom_lines_group_total'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_customer_supplied')) {
        obj['bom_lines_group_customer_supplied'] = ApiClient.convertToType(data['bom_lines_group_customer_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_fullfilled')) {
        obj['bom_lines_group_fullfilled'] = ApiClient.convertToType(data['bom_lines_group_fullfilled'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_not_found')) {
        obj['bom_lines_group_not_found'] = ApiClient.convertToType(data['bom_lines_group_not_found'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_out_of_stock')) {
        obj['bom_lines_group_out_of_stock'] = ApiClient.convertToType(data['bom_lines_group_out_of_stock'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_matching_exact')) {
        obj['bom_lines_group_matching_exact'] = ApiClient.convertToType(data['bom_lines_group_matching_exact'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_matching_xref')) {
        obj['bom_lines_group_matching_xref'] = ApiClient.convertToType(data['bom_lines_group_matching_xref'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_matching_manual')) {
        obj['bom_lines_group_matching_manual'] = ApiClient.convertToType(data['bom_lines_group_matching_manual'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_digikey_supplied')) {
        obj['bom_lines_group_digikey_supplied'] = ApiClient.convertToType(data['bom_lines_group_digikey_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_emsfactory_supplied')) {
        obj['bom_lines_group_emsfactory_supplied'] = ApiClient.convertToType(data['bom_lines_group_emsfactory_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_farnell_supplied')) {
        obj['bom_lines_group_farnell_supplied'] = ApiClient.convertToType(data['bom_lines_group_farnell_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_mouser_supplied')) {
        obj['bom_lines_group_mouser_supplied'] = ApiClient.convertToType(data['bom_lines_group_mouser_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_offline_supplied')) {
        obj['bom_lines_group_offline_supplied'] = ApiClient.convertToType(data['bom_lines_group_offline_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_rfq_supplied')) {
        obj['bom_lines_group_rfq_supplied'] = ApiClient.convertToType(data['bom_lines_group_rfq_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_rs_supplied')) {
        obj['bom_lines_group_rs_supplied'] = ApiClient.convertToType(data['bom_lines_group_rs_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_rutronik_supplied')) {
        obj['bom_lines_group_rutronik_supplied'] = ApiClient.convertToType(data['bom_lines_group_rutronik_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_ti_supplied')) {
        obj['bom_lines_group_ti_supplied'] = ApiClient.convertToType(data['bom_lines_group_ti_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_tme_supplied')) {
        obj['bom_lines_group_tme_supplied'] = ApiClient.convertToType(data['bom_lines_group_tme_supplied'], 'Number');
      }
      if (data.hasOwnProperty('bom_lines_group_wurth_supplied')) {
        obj['bom_lines_group_wurth_supplied'] = ApiClient.convertToType(data['bom_lines_group_wurth_supplied'], 'Number');
      }
      if (data.hasOwnProperty('pcba_with_xray_total')) {
        obj['pcba_with_xray_total'] = ApiClient.convertToType(data['pcba_with_xray_total'], 'Number');
      }
      if (data.hasOwnProperty('pcba_with_xray_full_board')) {
        obj['pcba_with_xray_full_board'] = ApiClient.convertToType(data['pcba_with_xray_full_board'], 'Number');
      }
      if (data.hasOwnProperty('pcba_with_xray_designators_total')) {
        obj['pcba_with_xray_designators_total'] = ApiClient.convertToType(data['pcba_with_xray_designators_total'], 'Number');
      }
      if (data.hasOwnProperty('pcba_with_coating_total')) {
        obj['pcba_with_coating_total'] = ApiClient.convertToType(data['pcba_with_coating_total'], 'Number');
      }
      if (data.hasOwnProperty('mpns_searched')) {
        obj['mpns_searched'] = ApiClient.convertToType(data['mpns_searched'], { 'String': 'Number' });
      }
      if (data.hasOwnProperty('mpns_not_found')) {
        obj['mpns_not_found'] = ApiClient.convertToType(data['mpns_not_found'], { 'String': 'Number' });
      }
      if (data.hasOwnProperty('mpns_out_of_stock')) {
        obj['mpns_out_of_stock'] = ApiClient.convertToType(data['mpns_out_of_stock'], { 'String': 'Number' });
      }
    }
    return obj;
  }


  quotations_created = undefined;

  quotations_priced = undefined;

  quotations_manually_priced = undefined;

  quotations_ordered = undefined;

  quotes_created = undefined;

  quotes_expired = undefined;

  orders_created = undefined;

  orders_expired = undefined;

  orders_validated = undefined;

  orders_manually_priced_validated = undefined;

  accounts_created = undefined;

  pcb_total = undefined;

  pcb_customer_supplied = undefined;

  pcb_auto_priced = undefined;

  pcb_manual_priced = undefined;

  pcb_rfq_priced = undefined;

  pcb_out_of_standard = undefined;

  pcb_eurocircuits_supplied = undefined;

  pcb_gatemapcb_supplied = undefined;

  pcb_mcbpcb_supplied = undefined;

  pcb_safepcb_supplied = undefined;

  pcb_wurthpcb_supplied = undefined;

  pcb_rfq_supplied = undefined;

  pcb_manual_supplied = undefined;

  bom_lines_total = undefined;

  bom_lines_erased = undefined;

  bom_lines_supplied = undefined;

  bom_lines_with_mpn = undefined;

  bom_lines_without_mpn = undefined;

  bom_lines_with_sku = undefined;

  bom_lines_without_sku = undefined;

  bom_lines_with_mpn_and_sku = undefined;

  bom_lines_with_mpn_without_sku = undefined;

  bom_lines_with_sku_without_mpn = undefined;

  bom_lines_with_description = undefined;

  bom_lines_with_description_only = undefined;

  bom_lines_without_description = undefined;

  bom_lines_with_xref_allowed = undefined;

  bom_lines_group_total = undefined;

  bom_lines_group_customer_supplied = undefined;

  bom_lines_group_fullfilled = undefined;

  bom_lines_group_not_found = undefined;

  bom_lines_group_out_of_stock = undefined;

  bom_lines_group_matching_exact = undefined;

  bom_lines_group_matching_xref = undefined;

  bom_lines_group_matching_manual = undefined;

  bom_lines_group_digikey_supplied = undefined;

  bom_lines_group_emsfactory_supplied = undefined;

  bom_lines_group_farnell_supplied = undefined;

  bom_lines_group_mouser_supplied = undefined;

  bom_lines_group_offline_supplied = undefined;

  bom_lines_group_rfq_supplied = undefined;

  bom_lines_group_rs_supplied = undefined;

  bom_lines_group_rutronik_supplied = undefined;

  bom_lines_group_ti_supplied = undefined;

  bom_lines_group_tme_supplied = undefined;

  bom_lines_group_wurth_supplied = undefined;

  pcba_with_xray_total = undefined;

  pcba_with_xray_full_board = undefined;

  pcba_with_xray_designators_total = undefined;

  pcba_with_coating_total = undefined;

  mpns_searched = undefined;

  mpns_not_found = undefined;

  mpns_out_of_stock = undefined;




  getQuotationsCreated() {
    return this.quotations_created;
  }

  setQuotationsCreated(quotationsCreated) {
    this['quotations_created'] = quotationsCreated;
  }

  getQuotationsPriced() {
    return this.quotations_priced;
  }

  setQuotationsPriced(quotationsPriced) {
    this['quotations_priced'] = quotationsPriced;
  }

  getQuotationsManuallyPriced() {
    return this.quotations_manually_priced;
  }

  setQuotationsManuallyPriced(quotationsManuallyPriced) {
    this['quotations_manually_priced'] = quotationsManuallyPriced;
  }

  getQuotationsOrdered() {
    return this.quotations_ordered;
  }

  setQuotationsOrdered(quotationsOrdered) {
    this['quotations_ordered'] = quotationsOrdered;
  }

  getQuotesCreated() {
    return this.quotes_created;
  }

  setQuotesCreated(quotesCreated) {
    this['quotes_created'] = quotesCreated;
  }

  getQuotesExpired() {
    return this.quotes_expired;
  }

  setQuotesExpired(quotesExpired) {
    this['quotes_expired'] = quotesExpired;
  }

  getOrdersCreated() {
    return this.orders_created;
  }

  setOrdersCreated(ordersCreated) {
    this['orders_created'] = ordersCreated;
  }

  getOrdersExpired() {
    return this.orders_expired;
  }

  setOrdersExpired(ordersExpired) {
    this['orders_expired'] = ordersExpired;
  }

  getOrdersValidated() {
    return this.orders_validated;
  }

  setOrdersValidated(ordersValidated) {
    this['orders_validated'] = ordersValidated;
  }

  getOrdersManuallyPricedValidated() {
    return this.orders_manually_priced_validated;
  }

  setOrdersManuallyPricedValidated(ordersManuallyPricedValidated) {
    this['orders_manually_priced_validated'] = ordersManuallyPricedValidated;
  }

  getAccountsCreated() {
    return this.accounts_created;
  }

  setAccountsCreated(accountsCreated) {
    this['accounts_created'] = accountsCreated;
  }

  getPcbTotal() {
    return this.pcb_total;
  }

  setPcbTotal(pcbTotal) {
    this['pcb_total'] = pcbTotal;
  }

  getPcbCustomerSupplied() {
    return this.pcb_customer_supplied;
  }

  setPcbCustomerSupplied(pcbCustomerSupplied) {
    this['pcb_customer_supplied'] = pcbCustomerSupplied;
  }

  getPcbAutoPriced() {
    return this.pcb_auto_priced;
  }

  setPcbAutoPriced(pcbAutoPriced) {
    this['pcb_auto_priced'] = pcbAutoPriced;
  }

  getPcbManualPriced() {
    return this.pcb_manual_priced;
  }

  setPcbManualPriced(pcbManualPriced) {
    this['pcb_manual_priced'] = pcbManualPriced;
  }

  getPcbRfqPriced() {
    return this.pcb_rfq_priced;
  }

  setPcbRfqPriced(pcbRfqPriced) {
    this['pcb_rfq_priced'] = pcbRfqPriced;
  }

  getPcbOutOfStandard() {
    return this.pcb_out_of_standard;
  }

  setPcbOutOfStandard(pcbOutOfStandard) {
    this['pcb_out_of_standard'] = pcbOutOfStandard;
  }

  getPcbEurocircuitsSupplied() {
    return this.pcb_eurocircuits_supplied;
  }

  setPcbEurocircuitsSupplied(pcbEurocircuitsSupplied) {
    this['pcb_eurocircuits_supplied'] = pcbEurocircuitsSupplied;
  }

  getPcbGatemapcbSupplied() {
    return this.pcb_gatemapcb_supplied;
  }

  setPcbGatemapcbSupplied(pcbGatemapcbSupplied) {
    this['pcb_gatemapcb_supplied'] = pcbGatemapcbSupplied;
  }

  getPcbMcbpcbSupplied() {
    return this.pcb_mcbpcb_supplied;
  }

  setPcbMcbpcbSupplied(pcbMcbpcbSupplied) {
    this['pcb_mcbpcb_supplied'] = pcbMcbpcbSupplied;
  }

  getPcbSafepcbSupplied() {
    return this.pcb_safepcb_supplied;
  }

  setPcbSafepcbSupplied(pcbSafepcbSupplied) {
    this['pcb_safepcb_supplied'] = pcbSafepcbSupplied;
  }

  getPcbWurthpcbSupplied() {
    return this.pcb_wurthpcb_supplied;
  }

  setPcbWurthpcbSupplied(pcbWurthpcbSupplied) {
    this['pcb_wurthpcb_supplied'] = pcbWurthpcbSupplied;
  }

  getPcbRfqSupplied() {
    return this.pcb_rfq_supplied;
  }

  setPcbRfqSupplied(pcbRfqSupplied) {
    this['pcb_rfq_supplied'] = pcbRfqSupplied;
  }

  getPcbManualSupplied() {
    return this.pcb_manual_supplied;
  }

  setPcbManualSupplied(pcbManualSupplied) {
    this['pcb_manual_supplied'] = pcbManualSupplied;
  }

  getBomLinesTotal() {
    return this.bom_lines_total;
  }

  setBomLinesTotal(bomLinesTotal) {
    this['bom_lines_total'] = bomLinesTotal;
  }

  getBomLinesErased() {
    return this.bom_lines_erased;
  }

  setBomLinesErased(bomLinesErased) {
    this['bom_lines_erased'] = bomLinesErased;
  }

  getBomLinesSupplied() {
    return this.bom_lines_supplied;
  }

  setBomLinesSupplied(bomLinesSupplied) {
    this['bom_lines_supplied'] = bomLinesSupplied;
  }

  getBomLinesWithMpn() {
    return this.bom_lines_with_mpn;
  }

  setBomLinesWithMpn(bomLinesWithMpn) {
    this['bom_lines_with_mpn'] = bomLinesWithMpn;
  }

  getBomLinesWithoutMpn() {
    return this.bom_lines_without_mpn;
  }

  setBomLinesWithoutMpn(bomLinesWithoutMpn) {
    this['bom_lines_without_mpn'] = bomLinesWithoutMpn;
  }

  getBomLinesWithSku() {
    return this.bom_lines_with_sku;
  }

  setBomLinesWithSku(bomLinesWithSku) {
    this['bom_lines_with_sku'] = bomLinesWithSku;
  }

  getBomLinesWithoutSku() {
    return this.bom_lines_without_sku;
  }

  setBomLinesWithoutSku(bomLinesWithoutSku) {
    this['bom_lines_without_sku'] = bomLinesWithoutSku;
  }

  getBomLinesWithMpnAndSku() {
    return this.bom_lines_with_mpn_and_sku;
  }

  setBomLinesWithMpnAndSku(bomLinesWithMpnAndSku) {
    this['bom_lines_with_mpn_and_sku'] = bomLinesWithMpnAndSku;
  }

  getBomLinesWithMpnWithoutSku() {
    return this.bom_lines_with_mpn_without_sku;
  }

  setBomLinesWithMpnWithoutSku(bomLinesWithMpnWithoutSku) {
    this['bom_lines_with_mpn_without_sku'] = bomLinesWithMpnWithoutSku;
  }

  getBomLinesWithSkuWithoutMpn() {
    return this.bom_lines_with_sku_without_mpn;
  }

  setBomLinesWithSkuWithoutMpn(bomLinesWithSkuWithoutMpn) {
    this['bom_lines_with_sku_without_mpn'] = bomLinesWithSkuWithoutMpn;
  }

  getBomLinesWithDescription() {
    return this.bom_lines_with_description;
  }

  setBomLinesWithDescription(bomLinesWithDescription) {
    this['bom_lines_with_description'] = bomLinesWithDescription;
  }

  getBomLinesWithDescriptionOnly() {
    return this.bom_lines_with_description_only;
  }

  setBomLinesWithDescriptionOnly(bomLinesWithDescriptionOnly) {
    this['bom_lines_with_description_only'] = bomLinesWithDescriptionOnly;
  }

  getBomLinesWithoutDescription() {
    return this.bom_lines_without_description;
  }

  setBomLinesWithoutDescription(bomLinesWithoutDescription) {
    this['bom_lines_without_description'] = bomLinesWithoutDescription;
  }

  getBomLinesWithXrefAllowed() {
    return this.bom_lines_with_xref_allowed;
  }

  setBomLinesWithXrefAllowed(bomLinesWithXrefAllowed) {
    this['bom_lines_with_xref_allowed'] = bomLinesWithXrefAllowed;
  }

  getBomLinesGroupTotal() {
    return this.bom_lines_group_total;
  }

  setBomLinesGroupTotal(bomLinesGroupTotal) {
    this['bom_lines_group_total'] = bomLinesGroupTotal;
  }

  getBomLinesGroupCustomerSupplied() {
    return this.bom_lines_group_customer_supplied;
  }

  setBomLinesGroupCustomerSupplied(bomLinesGroupCustomerSupplied) {
    this['bom_lines_group_customer_supplied'] = bomLinesGroupCustomerSupplied;
  }

  getBomLinesGroupFullfilled() {
    return this.bom_lines_group_fullfilled;
  }

  setBomLinesGroupFullfilled(bomLinesGroupFullfilled) {
    this['bom_lines_group_fullfilled'] = bomLinesGroupFullfilled;
  }

  getBomLinesGroupNotFound() {
    return this.bom_lines_group_not_found;
  }

  setBomLinesGroupNotFound(bomLinesGroupNotFound) {
    this['bom_lines_group_not_found'] = bomLinesGroupNotFound;
  }

  getBomLinesGroupOutOfStock() {
    return this.bom_lines_group_out_of_stock;
  }

  setBomLinesGroupOutOfStock(bomLinesGroupOutOfStock) {
    this['bom_lines_group_out_of_stock'] = bomLinesGroupOutOfStock;
  }

  getBomLinesGroupMatchingExact() {
    return this.bom_lines_group_matching_exact;
  }

  setBomLinesGroupMatchingExact(bomLinesGroupMatchingExact) {
    this['bom_lines_group_matching_exact'] = bomLinesGroupMatchingExact;
  }

  getBomLinesGroupMatchingXref() {
    return this.bom_lines_group_matching_xref;
  }

  setBomLinesGroupMatchingXref(bomLinesGroupMatchingXref) {
    this['bom_lines_group_matching_xref'] = bomLinesGroupMatchingXref;
  }

  getBomLinesGroupMatchingManual() {
    return this.bom_lines_group_matching_manual;
  }

  setBomLinesGroupMatchingManual(bomLinesGroupMatchingManual) {
    this['bom_lines_group_matching_manual'] = bomLinesGroupMatchingManual;
  }

  getBomLinesGroupDigikeySupplied() {
    return this.bom_lines_group_digikey_supplied;
  }

  setBomLinesGroupDigikeySupplied(bomLinesGroupDigikeySupplied) {
    this['bom_lines_group_digikey_supplied'] = bomLinesGroupDigikeySupplied;
  }

  getBomLinesGroupEmsfactorySupplied() {
    return this.bom_lines_group_emsfactory_supplied;
  }

  setBomLinesGroupEmsfactorySupplied(bomLinesGroupEmsfactorySupplied) {
    this['bom_lines_group_emsfactory_supplied'] = bomLinesGroupEmsfactorySupplied;
  }

  getBomLinesGroupFarnellSupplied() {
    return this.bom_lines_group_farnell_supplied;
  }

  setBomLinesGroupFarnellSupplied(bomLinesGroupFarnellSupplied) {
    this['bom_lines_group_farnell_supplied'] = bomLinesGroupFarnellSupplied;
  }

  getBomLinesGroupMouserSupplied() {
    return this.bom_lines_group_mouser_supplied;
  }

  setBomLinesGroupMouserSupplied(bomLinesGroupMouserSupplied) {
    this['bom_lines_group_mouser_supplied'] = bomLinesGroupMouserSupplied;
  }

  getBomLinesGroupOfflineSupplied() {
    return this.bom_lines_group_offline_supplied;
  }

  setBomLinesGroupOfflineSupplied(bomLinesGroupOfflineSupplied) {
    this['bom_lines_group_offline_supplied'] = bomLinesGroupOfflineSupplied;
  }

  getBomLinesGroupRfqSupplied() {
    return this.bom_lines_group_rfq_supplied;
  }

  setBomLinesGroupRfqSupplied(bomLinesGroupRfqSupplied) {
    this['bom_lines_group_rfq_supplied'] = bomLinesGroupRfqSupplied;
  }

  getBomLinesGroupRsSupplied() {
    return this.bom_lines_group_rs_supplied;
  }

  setBomLinesGroupRsSupplied(bomLinesGroupRsSupplied) {
    this['bom_lines_group_rs_supplied'] = bomLinesGroupRsSupplied;
  }

  getBomLinesGroupRutronikSupplied() {
    return this.bom_lines_group_rutronik_supplied;
  }

  setBomLinesGroupRutronikSupplied(bomLinesGroupRutronikSupplied) {
    this['bom_lines_group_rutronik_supplied'] = bomLinesGroupRutronikSupplied;
  }

  getBomLinesGroupTiSupplied() {
    return this.bom_lines_group_ti_supplied;
  }

  setBomLinesGroupTiSupplied(bomLinesGroupTiSupplied) {
    this['bom_lines_group_ti_supplied'] = bomLinesGroupTiSupplied;
  }

  getBomLinesGroupTmeSupplied() {
    return this.bom_lines_group_tme_supplied;
  }

  setBomLinesGroupTmeSupplied(bomLinesGroupTmeSupplied) {
    this['bom_lines_group_tme_supplied'] = bomLinesGroupTmeSupplied;
  }

  getBomLinesGroupWurthSupplied() {
    return this.bom_lines_group_wurth_supplied;
  }

  setBomLinesGroupWurthSupplied(bomLinesGroupWurthSupplied) {
    this['bom_lines_group_wurth_supplied'] = bomLinesGroupWurthSupplied;
  }

  getPcbaWithXrayTotal() {
    return this.pcba_with_xray_total;
  }

  setPcbaWithXrayTotal(pcbaWithXrayTotal) {
    this['pcba_with_xray_total'] = pcbaWithXrayTotal;
  }

  getPcbaWithXrayFullBoard() {
    return this.pcba_with_xray_full_board;
  }

  setPcbaWithXrayFullBoard(pcbaWithXrayFullBoard) {
    this['pcba_with_xray_full_board'] = pcbaWithXrayFullBoard;
  }

  getPcbaWithXrayDesignatorsTotal() {
    return this.pcba_with_xray_designators_total;
  }

  setPcbaWithXrayDesignatorsTotal(pcbaWithXrayDesignatorsTotal) {
    this['pcba_with_xray_designators_total'] = pcbaWithXrayDesignatorsTotal;
  }

  getPcbaWithCoatingTotal() {
    return this.pcba_with_coating_total;
  }

  setPcbaWithCoatingTotal(pcbaWithCoatingTotal) {
    this['pcba_with_coating_total'] = pcbaWithCoatingTotal;
  }

  getMpnsSearched() {
    return this.mpns_searched;
  }

  setMpnsSearched(mpnsSearched) {
    this['mpns_searched'] = mpnsSearched;
  }

  getMpnsNotFound() {
    return this.mpns_not_found;
  }

  setMpnsNotFound(mpnsNotFound) {
    this['mpns_not_found'] = mpnsNotFound;
  }

  getMpnsOutOfStock() {
    return this.mpns_out_of_stock;
  }

  setMpnsOutOfStock(mpnsOutOfStock) {
    this['mpns_out_of_stock'] = mpnsOutOfStock;
  }


}